import React from "react";
import { AiFillContacts } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { MdContactMail, MdContactPhone, MdEmail } from "react-icons/md";
import { useFormik } from "formik";
import { contactSchema } from "../../schemas"

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  message: ""
}

const Contact = () => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: contactSchema,
    onSubmit: (values, action) => {
      console.log(values);
      action.resetForm();
    }
  });
  // console.log(Formik);

  return (
    <div>
      <section
        className="pb-12 pt-8  md:px-24 px-2 md:space-y-8 space-y-12  overflow-hidden relative"
        data-aos="fade-up"
        id="contact"
      >
        <div className="">
          <div className="flex flex-col lg:flex-row lg:items-start text-slate-900 dark:text-gray-200 lg:justify-between -mx-4 gap-8">
            <div
              className="w-full lg:w-1/2 xl:w-7/12 md:px-0 px-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="  lg:mb-0 space-y-2">
                <p className="flex items-center uppercase text-left py-2 text-[22px] font-bold text-body-color leading-relaxed stand__out__text mx-5 border-b-2 border-primary">
                  Contact For Business Relations IN INDIA
                </p>
                <div className="flex md:flex-row flex-col justify-between md:divide-x-2 divide-x-0 md:divide-y-0 divide-y-2 gap-4 pb-4">
                  <div className="space-y-2">
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <AiFillContacts size={33} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
                        Sumit Aggarwal
                      </p>
                    </div>
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactMail size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                        sumit.aggarwal@i2vsys.com
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactPhone size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                        +91 981-005-6691
                      </p>
                    </div>

                  </div>
                  <div className="space-y-2">
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <AiFillContacts size={33} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
                        Lokesh Jain
                      </p>
                    </div>
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactMail size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                        lokesh.jain@i2vsys.com
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactPhone size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                        +91 830-766-5562
                      </p>
                    </div>

                  </div>
                </div>
                {/* <div className="flex px-4 gap-2 space-x-4">
                  <div className=" ">
                    <FaFacebookF className="rounded-full bg-black text-white w-12 h-12 text-center p-3 hover:bg-primary hover:scale-110 duration-500" />
                  </div>
                  <div className=" ">
                    <FaInstagram className="rounded-full bg-black text-white w-12 h-12 text-center p-3 hover:bg-primary hover:scale-110 duration-500" />
                  </div>
                  <div className=" ">
                    <FaTwitter className="rounded-full bg-black text-white w-12 h-12 text-center p-3 hover:bg-primary hover:scale-110 duration-500" />
                  </div>
                  <div className=" ">
                    <FaYoutube className="rounded-full bg-black text-white w-12 h-12 text-center p-3 hover:bg-primary hover:scale-110 duration-500" />
                  </div>
                </div> 
                -----------------
                <p className="flex items-center uppercase text-left py-2 text-[22px] font-bold text-body-color leading-relaxed stand__out__text mx-5 border-b-2 border-primary">
                  Contact For Business Relations IN AMERICA
                </p>
                <div className="flex md:flex-row flex-col justify-between md:divide-x-2 divide-x-0 md:divide-y-0 divide-y-2 gap-4 pb-4">
                  <div className="space-y-2">
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <AiFillContacts size={33} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
                        Rafael Ramirez
                      </p>
                    </div>
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactMail size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                      america@i2vsys.com
                      </p>
                    </div>
                    
                  </div>
                  
                </div> */}
                <div
                  className="w-full flex flex-col justify-between space-y-3"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                <p className="flex items-center uppercase text-left py-2 text-[22px] font-bold text-body-color leading-relaxed stand__out__text mx-5 border-b-2 border-primary">
                CONTACT FOR BUSINESS RELATIONS IN MIDDLE EAST COUNTRIES
                </p>
                <div className="flex md:flex-row flex-col justify-between md:divide-x-2 divide-x-0 md:divide-y-0 divide-y-2 gap-4 pb-4">
                  <div className="space-y-2">
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <AiFillContacts size={33} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
                      Mandeep Panwar
                      </p>
                    </div>
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactMail size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                      mandeep.panwar@i2vsys.com
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactPhone size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                      +971 558-427-756
                      </p>
                    </div>
                  </div>
                  </div>
                </div>

                
                <div
                  className="w-full flex flex-col justify-between space-y-3"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                <p className="flex items-center uppercase text-left py-2 text-[22px] font-bold text-body-color leading-relaxed stand__out__text mx-5 border-b-2 border-primary">
                CONTACT FOR CAREER AND JOBS
                </p>
                <div className="flex md:flex-row flex-col justify-between md:divide-x-2 divide-x-0 md:divide-y-0 divide-y-2 gap-4 pb-4">
                  <div className="space-y-2">
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <AiFillContacts size={33} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
                      Monika Khetarpal
                      </p>
                    </div>
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactMail size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                      careers@i2vsys.com
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactPhone size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                        +91 880-059-7104
                      </p>
                    </div>
                  </div>
                  
                </div>
                <div
                  className="w-full flex flex-col justify-between space-y-3"
                  data-aos="fade-up"
                  data-aos-delay="200"
                ></div>
                  
                  <div className=" space-y-2">
                    <p className="uppercase text-left py-2 text-lg font-bold text-body-color leading-relaxed stand__out__text mx-5 border-b-2 border-primary">
                      Contact For Product Queries & Technical Support
                    </p>

                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdEmail size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
                        i2v@i2vsys.com
                      </p>
                    </div>
                  </div>
                  <div className="  lg:mb-0 space-y-2">
                    <p className="uppercase text-left pt-4 text-lg font-bold text-body-color leading-relaxed stand__out__text mx-5 border-b-2 border-primary">
                      Corporate Office ( INDIA )
                    </p>
                    <div className="flex ">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed mb-9 mx-5">
                        <ImLocation2 size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
  <span>i2V Systems Pvt. Ltd.</span><br />
  <span>Software Technology Parks of India, 3rd & 4th Floor, Plot No 30, Electronic city, Sector 18, Gurugram-122015, Haryana, India.</span>
</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-full lg:w-1/2 xl:w-5/12 px-4"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="2000"
            >
              <div className="bg-white dark:bg-slate-800 relative rounded-xl lg:px-6 py-10 px-4 lg:py-12 shadow-2xl">
                {/* Form */}
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-2 gap-2 ">
                    <div className="flex mb-6 border rounded-lg items-center px-2 border-gray-400">
                      <img src="assets/images/person.png" alt="i2v-person" />
                      <input
                        type="name"
                        placeholder="First Name*"
                        className="border-1 rounded-lg
                                    w-full
                                    p-3
                                    text-gray-800
                                    border-gray-300
                                    outline-none
                                    focus-visible:shadow-none
                                    focus:border-primary
                                    "
                        name="fname"
                        id="fname"
                        value={values.fname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div className="flex mb-6 border rounded-lg items-center px-2 border-gray-400">
                      <img src="assets/images/person.png" alt="i2v-person" />
                      <input
                        type="name"
                        placeholder="Last Name"
                        className="border-1 rounded-lg
                                    w-full
                                    p-3
                                    text-gray-800
                                    
                                    outline-none
                                    focus-visible:shadow-none
                                    focus:border-primary
                                    "
                        name="lname"
                        id="lname"
                        value={values.lname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <div className=" flex justify-center items-center border rounded-lg px-2 border-gray-400">
                      <img src="assets/images/email.png" alt="i2v-person" />
                      <input
                        type="email"
                        placeholder="Email ID*"
                        className="
                                    w-full
                                    p-3
                                    text-gray-800
                                    border-gray-300
                                    outline-none
                                    focus-visible:shadow-none
                                    focus:border-primary
                                    "
                        name="email"
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.email && touched.email ? <p className='text-primary'>{errors.email}</p> : null}

                  </div>
                  <div className="mb-6 ">
                    <div className="flex justify-center items-center border rounded-lg px-2 border-gray-400">
                      <img src="assets/images/phone.png" alt="i2v-person" />
                      <input
                        type="phone"
                        placeholder="Phone Number*"
                        className="
                                    w-full
                                    p-3
                                    text-gray-800
                                    border-gray-300
                                    outline-none
                                    focus-visible:shadow-none
                                    focus:border-primary
                                    "
                        name="phone"
                        id="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.phone && touched.phone ? <p className='text-primary'>{errors.phone}</p> : null}

                  </div>

                  <div className="mb-6 flex justify-center  border rounded-lg px-2 border-gray-400">
                    <div>
                      <img src="assets/images/message.png" alt="i2v-person" className="pt-5" />
                    </div>
                    <textarea
                      type="text"
                      placeholder="Type Message*"
                      className="
                                    w-full
                                    p-3
                                    text-gray-800
                                    border-gray-300
                                    outline-none
                                    focus-visible:shadow-none
                                    focus:border-primary
                                    "
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex justify-start">
                    <button
                      type="submit"
                      className="
                                    w-40
                                    text-lg
                                    font-semibold
                                    text-primary
                                    bg-black
                                    rounded-lg
                                    border border-primary
                                    hover:bg-primary
                                    hover:text-white
                                    p-2
                                    transition
                                    ease-in-out
                                    duration-500
                                    "
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="h-96 text-slate-900 dark:text-gray-200 lg:justify-between mb-12">

            <div className="md:container md:mx-auto md:relative">
              <div className="lg:absolute  md:container z-10  ">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.301317914257!2d77.06733461432121!3d28.500580796793155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d194016886377%3A0x43e384262dc3195!2si2V%20Systems%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1668607570654!5m2!1sen!2sin"
                  width="100%"
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  className=" -top-10 rounded-[20px] "
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
